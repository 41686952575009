<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left ml-2 mr-2"></i><span class="ml-2">&nbsp; &nbsp;</span>Accueil</a></li>
            <li class="breadcrumb-item"><a href="/journees">Récap semaine</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">
        <div class="card  rounded-0 rounded-top bg-primary bg-img" style="background-image: url('img/core-img/1.png')">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Récap du {{journee.date|formatDay|capitalize}} {{journee.date|formatDate}}</h6>
          </div>
        </div>
       
        <div class="card rounded-0 rounded-bottom">
            <ul class="list-group">
                <li class="list-group-item"><i class="fas fa-solid fa-calendar-check" style="padding-right:1rem;"></i>Date : {{journee.date|formatDate}}</li>
                <li class="list-group-item"><i class="fas fa-solid fa-user" style="padding-right:1rem;"></i>Chauffeur : {{lsUserData.collaborateur.fullname}}</li>
                <li class="list-group-item"><i class="fas fa-solid fa-tag" style="padding-right:1rem;"></i>Activité : {{journee.activite}}</li>
                <li class="list-group-item"><i class="fas fa-solid fa-crosshairs" style="padding-right:1rem;"></i>Zone : {{journee.zone}}</li>
                <li class="list-group-item"><i class="fas fa-solid fa-utensils" style="padding-right:1rem;"></i>Repas : {{journee.repas}}</li>
                <li class="list-group-item"><i class="fas fa-solid fa-clock" style="padding-right:1rem;"></i>Heures : {{journee.nbHeures}}</li>
            </ul>
        </div>
        
        </div> 
    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';


export default {
  name: 'Journee',
  data: function() {
    return {
      lsUserData: [],
      loggedIn: this.$store.state.auth.status.loggedIn,
      journee: [],
    }
  },
  components: {
    Header,
    Footer,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    },
    formatDay: function (value) {
      if (!value) return ''
      var options = { weekday: 'long'};
      value = new Date(value).toLocaleDateString(undefined, options);
       return value;
    },
    capitalize: function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  }, 
  async created() {
    await UserService.getJournee(this.$route.params.id).then((response) => {
        this.journee = response.data;
        this.lsUserData = JSON.parse(localStorage.getItem('userData'));
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
  },
  mounted() {
  
  },
  methods: {
   
  },
}
</script>
<style>
.list-group-item {
  text-align: left;
}
</style>