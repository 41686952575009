import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Interventions from '../views/Interventions.vue'
import Intervention from '../views/Intervention.vue'
import ClotureJournee from '../views/JourneeCloture.vue'
import Journee from '../views/Journee.vue'
import Journees from '../views/Journees.vue'
import Documents from '../views/Documents.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/',
    alias: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/interventions',
    name: 'interventions',
    component: Interventions
  },
  {
    path: '/interventions/:id',
    name: 'intervention',
    component: Intervention
  },
  {
    path: '/journee',
    name: 'cloturejournee',
    component: ClotureJournee
  },
  {
    path: '/journees',
    name: 'journees',
    component: Journees
  },
  {
    path: '/journee/:id',
    name: 'journee',
    component: Journee
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router