<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left ml-2 mr-2"></i><span class="ml-2">&nbsp; &nbsp;</span>Accueil</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">
        <div class="card  rounded-0 rounded-top bg-primary bg-img" style="background-image: url('img/core-img/1.png')">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Récap semaine</h6>
          </div>
        </div>

        
        <ul class="ps-0 chat-user-list rounded-0">
          <li v-for="(journee, index) in data" :key="journee.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (data.length-1) }">
            <a v-if="journee.value.id" class="d-flex" v-bind:href="'/journee/' + journee.value.id">
              <div class="chat-user-info" style="width:100%;">
                <div class="row">
                  <div class="col-8">
                    <h6 class="mt-2 mb-1">{{journee.name|capitalize}} {{journee.value.date|formatDate}}</h6>
                  </div>
                  <div class="col-4 text-right" style="align-content:left;">
                      <span class="badge bg-success" style="margin-right:10px;"><i class="bi bi-check-circle"></i></span>
                      <span class="badge rounded-pill" v-bind:class="journee.value.repas == 'Panier' ? 'bg-info' : 'bg-secondary'">{{journee.value.repas == 'Panier' ? 'P' : 'R'}}</span>
                  </div>
                </div>
              </div>
            </a>
            <div v-else class="chat-user-info" style="width:100%;">
              <div class="row">
                <div class="col-8" style="padding-right:0;">
                  <h6 class="mt-2 mb-1">{{journee.name|capitalize}}</h6>
                </div>
                <div class="col-4 text-right" style="align-content:left; padding-left:0;padding-right:15px;">
                    <span class="badge bg-danger"><i class="bi bi-x-circle"></i></span>
                </div>
              </div>
            </div>
            <div v-if="journee.value.id" class="dropstart chat-options-btn">
              <a v-bind:href="'/journee/' + journee.value.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
            <!--
            <a class="d-flex" v-bind:href="'/journee/' + journee.id">
              
              <div class="chat-user-info" style="width:100%;">
                <div class="row">
                  <div class="col-8">
                    <h6 class="mt-2 mb-1">{{journee.date|formatDay|capitalize}} {{journee.date|formatDate}}</h6>
                  </div>
                  <div class="col-4 text-right">
                    <span class="badge rounded-pill" v-bind:class="journee.repas == 'Panier' ? 'bg-info' : 'bg-secondary'" style="margin-right:10px;">{{journee.repas == 'Panier' ? 'P' : 'R'}}</span>
                    <span class="badge rounded-pill bg-success"><i class="fas fa-solid fa-check"></i></span>
                  </div>
                </div>
              </div>
            </a>
            
            <div class="dropstart chat-options-btn">
              <a v-bind:href="'/journee/' + journee.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
            -->
          </li>
        </ul>
        
    </div>
    </div>
    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'Journees',
  data: function() {
    return {
      lsUserData: [],
      journees: [],
      loggedIn: this.$store.state.auth.status.loggedIn,
      data: [],
      weekNumber: 0,
    }
  },
  components: {
    Header,
    Footer,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    },
    formatDay: function (value) {
      if (!value) return ''
      var options = { weekday: 'long'};
      value = new Date(value).toLocaleDateString(undefined, options);
      return value;
    },
    capitalize: function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  }, 
  async created() {
    this.lsUserData = JSON.parse(localStorage.getItem('userData'));
    await UserService.getJournees('?cId=' + this.lsUserData.collaborateur.id).then(
        response => {
          this.journees = response.data['hydra:member'];

          this.data = [
            {name: 'lundi', value : []},
            {name: 'mardi', value : []},
            {name: 'mercredi', value : []},
            {name: 'jeudi', value : []},
            {name: 'vendredi', value : []},
          ];

            this.journees.forEach(journee => {
            var options = { weekday: 'long'};
            var day = new Date(journee.date).toLocaleDateString(undefined, options);

            this.data.forEach(element => {
                if (element.name == day) {
                element.value = journee;
                }
            });
          });
          console.log(this.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      
      // récupère le numéro de la semaine en cours
      var currentdate = new Date();
      var oneJan = new Date(currentdate.getFullYear(),0,1);
      var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
      this.weekNumber = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
  },
  methods: {
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>