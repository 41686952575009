<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left ml-2 mr-2"></i><span class="ml-2">&nbsp; &nbsp;</span>Accueil</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">
        <div class="card  rounded-0 rounded-top bg-primary bg-img" style="background-image: url('img/core-img/1.png')">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Bons de transport</h6>
          </div>
        </div>

        
        <ul v-if="interventions.length > 0 " class="ps-0 chat-user-list rounded-0">
          <li v-for="(intervention, index) in interventions" :key="intervention.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (interventions.length-1) }">
            <a class="d-flex" v-bind:href="'/interventions/' + intervention.id">
              
              <div class="chat-user-info">
                <h6 class="mt-2 mb-1">{{intervention.date|formatDay|capitalize}} {{intervention.date|formatDate}} - {{intervention.client.raisonSocial}}</h6>

                <div class="last-chat">
                  <div class="row">
                    <div class="col-10 text-left">
                      <p class="mb-2">
                        <span class="badge" v-bind:class="intervention.signatureClient ? 'bg-success' : 'bg-danger'">{{intervention.contact.firstName}} {{intervention.contact.lastName}}</span> 
                      </p>
                    </div>
                    <div class="col-2">
                      <p class="mb-2">
                        <InterventionStatut :statut="intervention.status" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div class="dropstart chat-options-btn">
              <a v-bind:href="'/interventions/' + intervention.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </li>
        </ul>
        <div v-else class="card bg-danger rounded-0 rounded mt-2">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Aucun bon de transport</h6>
          </div>
        </div>
        <div class="add-new-contact-wrap"><a class="shadow" href="#" data-bs-toggle="modal" data-bs-target="#newBT"><i class="bi bi-plus-lg"></i></a></div>
          <div class="add-new-contact-modal modal fade px-0" id="newBT" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalBT" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <InterventionForm :type="formType" />
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import InterventionForm from '@/components/InterventionForm.vue'
import InterventionStatut from '@/components/InterventionStatut.vue'
import UserService from '../services/user.service';

export default {
  name: 'Interventions',
  data: function() {
    return {
      lsUserData: [],
      interventions: [],

      loggedIn: this.$store.state.auth.status.loggedIn,
      urlUploads: this.$urlUploads,
      formType: '',
    }
  },
  components: {
    Header,
    Footer,
    InterventionForm,
    InterventionStatut
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    },
    formatDay: function (value) {
      if (!value) return ''
      var options = { weekday: 'long'};
      value = new Date(value).toLocaleDateString(undefined, options);
       return value;
    },
    capitalize: function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  }, 
  async created() {
    this.lsUserData = JSON.parse(localStorage.getItem('userData'));

    if(this.lsUserData.collaborateur.length === 0) {
    await UserService.getInterventions('?sId=' + this.lsUserData.user.siteId).then(
        response => {
          this.interventions = response.data['hydra:member'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      )
    } 
    else {
      await UserService.getInterventions('?cId=' + this.lsUserData.collaborateur.id).then(
          response => {
            this.interventions = response.data['hydra:member'];
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        )
    }
    
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>