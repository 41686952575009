<template>
  <div>
    <!-- statut -->
        <span v-if="statut == 'in_project'" class="badge bg-secondary">En projet</span>
        <span v-if="statut == 'awaiting_confirmation'" class="badge bg-warning">En attente de confirmation</span>
        <span v-if="statut == 'confirmed'" class="badge bg-info">Confirmée</span>
        <span v-if="statut == 'in_progress'" class="badge bg-primary">En cours</span>
        <span v-if="statut == 'done'" class="badge bg-success">Réalisée</span>
        <span v-if="statut == 'closed'" class="badge bg-success">Clôturée</span>
        <span v-if="statut == 'canceled'" class="badge bg-danger">Annulée</span>
  </div>
</template>

<script>

export default {
  name: 'InterventionStatut',
  props: ['statut'],
  data: function() {
    return {
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>