<template>
  <div>

    <Header />

    <div class="page-content-wrapper">
      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded"  style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp; &nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/interventions">Liste des bons de transport</a></li>
          </ol>
        </nav>
      </div>
      
       <div class="container mb-3">
        <div class="card  rounded-0 rounded-top bg-primary bg-img" style="background-image: url('/img/core-img/1.png')">
          <div class="card-body py-3">
            <div class="d-flex align-items-center justify-content-between py-1">
              <h6 class="mt-2 text-left text-white pt-2">
                Bon de transport du {{ intervention.date|formatDate }}
              </h6>
              <span></span>
              <a href="#" class="ps-3" data-bs-toggle="modal" data-bs-target="#modalUpdateIntervention"><i class="card-btn fas fa-pencil-alt text-white"></i></a>
            </div>
          </div>
        </div>
                  
        <!-- modal update Intervention -->
          <div class="add-new-contact-modal modal fade px-0" id="modalUpdateIntervention" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalIntervention" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <InterventionForm :type="formType" />
                </div>
              </div>
            </div>
          </div>

           <div class="card text-left rounded-0 rounded-bottom">
          <table class="table mb-0">
                  <tbody>
                    <tr>
                      <th>Date :</th>
                      <td>{{intervention.date|formatDate}}</td>
                    </tr>
                    <tr>
                      <th>N° bon de transport :</th>
                      <td>{{intervention.code}}</td>
                    </tr>
                    <tr>
                      <th>N° bon de commande :</th>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Client :</th>
                      <td>{{intervention.client.raisonSocial}}</td>
                    </tr>
                    <tr>
                        <th>Représentant client :</th>
                        <td>{{intervention.contact.firstName ? intervention.contact.firstName : ''}} {{intervention.contact.lastName}}</td>
                    </tr>
                    <tr>
                        <th>Chauffeur :</th>
                        <td>{{intervention.collaborateur.firstName}} {{intervention.collaborateur.lastName}}</td>
                    </tr>
                    <tr>
                        <th>Equipement :</th>
                        <td>{{intervention.equipment.code}} - {{intervention.equipment.name}}</td>
                    </tr>
                    <tr>
                        <th>Adresse du chantier :</th>
                        <td>{{intervention.address1}} 
                          {{intervention.zipCode}} - {{intervention.city}}
                        </td>
                    </tr>
                    <tr>
                        <th>Observation :</th>
                        <td>{{intervention.description}}</td>
                    </tr>
                    <tr>
                        <th>Durée :</th>
                        <td>{{intervention.durationDec}}</td>
                    </tr>
                    <tr>
                        <th>Signature :</th>
                        <td><img :src="this.$urlUploads + intervention.signatureClient" /></td>
                    </tr>
                    <tr>
                        <th>Statut :</th>
                        <td><InterventionStatut :statut="intervention.status" /></td>
                    </tr>
                  </tbody>
                </table>  
        </div>
       
      </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import InterventionForm from '@/components/InterventionForm.vue'
import InterventionStatut from '@/components/InterventionStatut.vue'
import UserService from '../services/user.service';

export default {
  name: 'intervention',
  components: {
    Header,
    Footer,
    InterventionForm,
    InterventionStatut
  },
  data: function() {
    return {
      formType: '',
      intervention: [],
      userData: [],
      id: this.$route.params.id,
      urlUploads: this.$urlUploads,
      urlWeb: this.$urlWeb,
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {
    await UserService.getIntervention(this.$route.params.id).then((response) => {
        this.intervention = response.data;
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    this.userData = JSON.parse(localStorage.getItem('userData'));
  },
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>