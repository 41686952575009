<template>
  <div>
        <div class="card mb-3">
          <div class="card-body">
            <!-- intervention form -->
            <div class="mt-3">
              <h6 class="mb-3 text-center">{{ editing ? 'Modifier' : 'Enregistrer' }} un bon de transport</h6>

            <FormulateForm
                @submit="validationForm"
              >
                <FormulateInput
                    type="datepicker"
                    language="fr"
                    name="date"
                    label="Date"
                    format="dd/MM/yyyy"
                    v-model="intervention.date"
                    validation="required"
                    :validation-messages="{
                      required: 'La date est obligatoire'
                    }"
                  />
                <FormulateInput
                  type="text"
                  label="N° bon commande client"
                  placeholder=""
                  v-model="intervention.codeClient"
                />

                <div class="text-left mb-1"><label>Client</label></div>
                <Multiselect 
                  v-model="intervention.client" 
                  :options="formSelectClients"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectClients.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le nom du client"
                  validation="required"
                  :validation-messages="{
                      required: 'Le client est obligatoire'
                    }"
                ></Multiselect>
                
                <div class="text-left mb-1 mt-3"><label>Chauffeur</label></div>
                <Multiselect 
                  v-model="intervention.collaborateur" 
                  :options="formSelectCollaborateur"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectCollaborateur.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le chauffeur"
                  validation="required"
                  :validation-messages="{
                      required: 'Le chauffeur est obligatoire'
                    }"
                ></Multiselect>

                <div class="text-left mb-1 mt-3"><label>Type de matériel</label></div>
                <Multiselect 
                  v-model="intervention.equipment" 
                  :options="formSelectEquipmentsFavoris"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectEquipmentsFavoris.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner un équipement"
                  validation="required"
                  :validation-messages="{
                      required: 'L\'équipement est obligatoire'
                    }"
                ></Multiselect>

                <div class="mt-3"></div>
                
                <FormulateInput
                  type="text"
                  label="Adresse du chantier"
                  placeholder=""
                  v-model="intervention.address1"
                />
                <FormulateInput
                  type="text"
                  label="Code postal"
                  placeholder=""
                  v-model="intervention.zipCode"
                />
                <FormulateInput
                  type="text"
                  label="Ville"
                  placeholder=""
                  v-model="intervention.city"
                />

                <FormulateInput
                  type="textarea"
                  label="Observation"
                  v-model="intervention.description"
                />

                <div  class="text-left mb-2">
                  <label>Temps de location</label>
                </div>
                <div class="input-group mb-3">
                  <input class="form-control" type="number" min="0" max="13" step="0.25" aria-label="" placeholder="1,5"  v-model="intervention.durationDec"><span class="input-group-text">H</span>
                </div>
          
                <div class="text-left mb-1"><label>Représentant client</label></div>
                <Multiselect 
                  v-model="intervention.contact" 
                  :options="formSelectContacts"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectContacts.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner un représentant"
                ></Multiselect>

                <div class="mt-3"></div>

                <FormulateInput
                  type="text"
                  label="Représentant client"
                  placeholder="Si non présent dans la liste"
                  v-model="intervention.representantClient"
                />

                <div v-if="editing == false" id="signature-pad" class="text-left">
                  <label>Signature du représentant</label>
                  <VueSignaturePad v-model="intervention.signatureClientBase64" height="200px" ref="signaturePad" class="border mb-4" />
                  <div>
                    <button @click="undo" class="btn btn-secondary mb-2">Annuler signature</button>
                  </div>
                </div>

                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="boutonIntervention"
                  input-class="btn btn-success w-100 text-white"
                />
              </FormulateForm>
            </div>
           </div>
        </div>
  </div>
</template>           

<script>
import router from '../router'
import UserService from '../services/user.service';

var temp = Object.freeze({
     date: '',
     codeClient: '',
     client: '',
     address1: '',
     city: '',
     zipCode: '',
     contact: null,
     equipment: '',
     collaborateur: '',
     signatureClientBase64: '',
     durationDec: '',
     description: '',
     representantClient: '',

    });

export default {
  name: 'Form',
  components: {
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      userData: [],
      error: null,
      intervention: Object.assign({}, temp),
      editing: false,
      urlUploads: this.$urlUploads,
      interventionNewId: null,
      formSelectCollaborateur: [],
      formSelectClients: [],
      formSelectContacts: [],
      formSelectEquipmentsFavoris: [],
      clientId: null,
      contactId: null,
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

   // clients form
    await UserService.getClientsForm().then(
      response => {
        localStorage.setItem('clientsForm', JSON.stringify(response.data));
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );

    const lsClientsForm = JSON.parse(localStorage.getItem('clientsForm'));
    if( lsClientsForm ) { this.formSelectClients = lsClientsForm['hydra:member']; }

    // contacts form
    await UserService.getContactsForm().then(
      response => {
        localStorage.setItem('contactForm', JSON.stringify(response.data));
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );

    const lsContactsForm = JSON.parse(localStorage.getItem('contactForm'));
    if( lsContactsForm ) { this.formSelectContacts = lsContactsForm['hydra:member']; }

    // collaborateurs form
    if (this.userData.collaborateur.length == 0) {
       await UserService.getCollaborateursForm().then(
        response => {
          localStorage.setItem('collaborateurForm', JSON.stringify(response.data));
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

      const lsCollaborateursForm = JSON.parse(localStorage.getItem('collaborateurForm'));
      if( lsCollaborateursForm ) { this.formSelectCollaborateur = lsCollaborateursForm['hydra:member']; }
    } else {
      this.formSelectCollaborateur =[{ value: this.userData.collaborateur.id, label: this.userData.collaborateur.code + ' - ' + this.userData.collaborateur.fullname }];
    }

    // equipments favoris du collaborateur si y'en a un 
    await UserService.getEquipmentsForm().then(
      response => {
        localStorage.setItem('equipmentFavorisForm', JSON.stringify(response.data));
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );

    const lsEquipmentsFavoris = JSON.parse(localStorage.getItem('equipmentFavorisForm'));
    if( lsEquipmentsFavoris ) { this.formSelectEquipmentsFavoris = lsEquipmentsFavoris['hydra:member']; }

    // initialise canvas ratio
    var wrapper = document.getElementById("signature-pad");
    var canvas = wrapper.querySelector("canvas");
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = 200 * ratio;
    canvas.height = 200 * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
},
  watch: {
    type(val) {
      if (val == 'add') {
        this.intervention = Object.assign({}, temp);
      }
    }
  },

  mounted() {
    if ("id" in this.$route.params) {
      this.getInterventionById();
      this.editing = true;
    } else {
      this.intervention.date = new Date();
      this.intervention.collaborateur = this.userData.collaborateur ? {value : this.userData.collaborateur.id, label: this.userData.collaborateur.code + ' - ' + this.userData.collaborateur.fullname} : '';

    }
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    getInterventionById() {
      UserService.getIntervention(this.$route.params.id).then( 
          response => {
                    this.intervention = response.data;
                    //var valueContact = document.getElementsByName('contact');
                    //valueContact[0].id = this.intervention.contact.id;
                    //this.contactId = this.intervention.contact.id;
                    //console.log(this.intervention.contact)
                    //this.intervention.contact = this.intervention.contact.firstName + ' ' + this.intervention.contact.lastName + ' (' + this.intervention.contact.organisme.name + ')';
                    this.intervention.contact.label = this.intervention.contact.firstName + ' ' + this.intervention.contact.lastName + ' (' + this.intervention.contact.organisme.name + ')';
                    this.intervention.contact.value = this.intervention.contact.id;

                    //var valueClient = document.getElementsByName('client');
                    //valueClient[0].id = this.intervention.client.id;
                    //this.clientId = this.intervention.client.id;
                    //this.intervention.client = this.intervention.client.raisonSocial;
                    this.intervention.client.label = this.intervention.client.raisonSocial;
                    this.intervention.client.value = this.intervention.client.id;

                    //this.intervention.equipment = this.intervention.equipment.id;
                    this.intervention.equipment.label = this.intervention.equipment.code + ' - ' + this.intervention.equipment.name;
                    this.intervention.equipment.value = this.intervention.equipment.id;

                    //this.intervention.collaborateur = this.intervention.collaborateur.id;
                    this.intervention.collaborateur.label = this.intervention.collaborateur.code + ' - ' + this.intervention.collaborateur.firstName + ' ' + this.intervention.collaborateur.lastName;
                    this.intervention.collaborateur.value = this.intervention.collaborateur.id;
                },
          error => {this.content =(error.response && error.response.data) || error.message || error.toString();}
        );
    },
    async reload() {
      await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
          this.userData = JSON.parse(localStorage.getItem('userData'));
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
        if("id" in this.$route.params) {
          //var valueClientE = document.getElementsByName('client');
          //var valueContactE = document.getElementsByName('contact');
            const intervention = {
              date: this.intervention.date,
              codeClient: this.intervention.codeClient,
              client: "api/clients/" + this.intervention.client.value,
              contact: this.intervention.contact.value ? "api/contacts/" + this.intervention.contact.value : null,
              representantClient: this.intervention.representantClient,
              collaborateur: "api/collaborateurs/" + this.intervention.collaborateur.value,
              equipment: "api/equipment/" + this.intervention.equipment.value,
              address1: this.intervention.address1,
              zipCode: this.intervention.zipCode,
              city: this.intervention.city,
              durationDec: this.intervention.durationDec,
              description: this.intervention.description,
              updatedBy: "api/users/" + this.userData.user.id
            };
            console.log(intervention)
            
            await UserService.patchIntervention(this.$route.params.id, intervention).then(
                () => {
                    UserService.getUserMe().then(
                        response => {
                            localStorage.setItem('userData', response.data);
                        },
                        error => {
                            this.content = (error.response && error.response.data) || error.message || error.toString();
                        }
                    );
                },
            );
            document.getElementById('boutonIntervention').click();
            router.push('/interventions/' + this.$route.params.id);
            this.reload();
            
        } else {
          //const data = this.$refs.signaturePad.toData("image/jpeg");
          const { data } = this.$refs.signaturePad.saveSignature("image/png");
          //console.log(data);
          //var valueClient = document.getElementsByName('client');
          //var valueContact = document.getElementsByName('contact');
            const intervention = {
              site: "api/sites/" + this.userData.user.siteId, 
              date: this.intervention.date,
              codeClient: this.intervention.codeClient,
              client: "api/clients/" + this.intervention.client.value,
              contact: this.intervention.contact ? "api/contacts/" + this.intervention.contact.value : null,
              representantClient: this.intervention.representantClient,
              collaborateur: "api/collaborateurs/" + this.intervention.collaborateur.value,
              equipment: "api/equipment/" + this.intervention.equipment.value,
              address1: this.intervention.address1,
              zipCode: this.intervention.zipCode,
              city: this.intervention.city,
              durationDec: this.intervention.durationDec,
              description: this.intervention.description,
              signatureClientBase64 : data,
              status: "done",
              createdBy: "api/users/" + this.userData.user.id,
            };
            //console.log(intervention)
            //console.log(this.$refs.signaturePad)
            //console.log(data)
            
            await UserService.postIntervention(intervention).then(
                res => {
                  let interventionId = res.data['@id'];
                  this.interventionNewId = interventionId.slice(19);
                    UserService.getUserMe().then(
                        response => {
                            localStorage.setItem('userData', response.data);
                        },
                        error => {
                            this.content = (error.response && error.response.data) || error.message || error.toString();
                        }
                    );
                },
                
            );
           router.push('/interventions/' + this.interventionNewId);
           this.reload();
            
        }
    },  
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vue__time-picker {
  width: 100%;
}
.vue__time-picker input.display-time {
  width: 100%;
  border-style: solid;
  padding: 0.5rem 1rem;
  height: 41px;
  max-height: 41px;
  font-size: 14px;
  background-color: #ffffff;
  border-width: 1px;
  border-color: #ebebeb;
  border-radius: 5px;
}
canvas {
  aspect-ratio: auto 200/200;
}
</style>