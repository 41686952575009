<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left ml-2 mr-2"></i><span class="ml-2">&nbsp; &nbsp;</span>Accueil</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">
        <div class="card  rounded-0 rounded-top bg-primary bg-img" style="background-image: url('img/core-img/1.png')">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Clôture journée</h6>
          </div>
        </div>
       
        

        <div class="card">
            <div v-if="isDone == false" class="card-body">

              <FormulateForm
                @submit="validationForm"
              >
            <div class="minimal-tab">
                <ul class="nav nav-tabs mb-3" id="affanTab2" role="tablist">
                    <li class="nav-item" role="presentation">
                    <button class="btn active" id="sass-tab" data-bs-toggle="tab" data-bs-target="#sass" type="button" role="tab" aria-controls="sass" aria-selected="true">Intervention</button>
                    </li>
                    <li class="nav-item" role="presentation">
                    <button class="btn" id="npm-tab" data-bs-toggle="tab" data-bs-target="#npm" type="button" role="tab" aria-controls="npm" aria-selected="false">Activités</button>
                    </li>
                </ul>

                <div class="tab-content rounded-lg p-3" id="affanTab2Content">
                    <div class="tab-pane fade show active" id="sass" role="tabpanel" aria-labelledby="sass-tab">
                    
                        <FormulateInput
                          type="datepicker"
                          language="fr"
                          name="date"
                          label="Date"
                          format="dd/MM/yyyy"
                          v-model="journee.date"
                        />

                        <div class="form-group text-left">
                            <label class="form-label" for="primaryRadio">Zone</label>
                            <div class="single-plan-check shadow-sm active-effect">
                            <div class="form-check" style="padding-left: 0;">
                                <input v-model="radioZone" value="Zone 1" class="form-check-input" type="radio" name="exampleRadio" id="z1" style="margin-left:-.5em;">
                                <label class="form-check-label" for="z1">1</label>
                            </div>
                            <div class="form-check pl-0">
                                <input v-model="radioZone" value="Zone 2" class="form-check-input" type="radio" name="exampleRadio" id="z2" style="margin-left:-.5em;">
                                <label class="form-check-label" for="z2">2</label>
                            </div>
                            <div class="form-check">
                                <input v-model="radioZone" value="Zone 3" class="form-check-input" type="radio" name="exampleRadio" id="z3" style="margin-left:-.5em;">
                                <label class="form-check-label" for="z3">3</label>
                            </div>
                            <div class="form-check">
                                <input v-model="radioZone" value="Zone 4" class="form-check-input" type="radio" name="exampleRadio" id="z4" style="margin-left:-.5em;">
                                <label class="form-check-label" for="z4">4</label>
                            </div>
                            <div class="form-check">
                                <input v-model="radioZone" value="Zone 5" class="form-check-input" type="radio" name="exampleRadio" id="z5" style="margin-left:-.5em;">
                                <label class="form-check-label" for="z5">5</label>
                            </div>
                            <div class="form-check">
                                <input v-model="radioZone" value="Zone 6" class="form-check-input" type="radio" name="exampleRadio" id="z6" style="margin-left:-.5em;">
                                <label class="form-check-label" for="z6">6</label>
                            </div>
                            <div class="form-check">
                                <input v-model="radioZone" value="Zone 7" class="form-check-input" type="radio" name="exampleRadio" id="z7" style="margin-left:-.5em;">
                                <label class="form-check-label" for="z7">7</label>
                            </div>
                            <div class="form-check">
                                <input v-model="radioZone" value="Zone 8" class="form-check-input" type="radio" name="exampleRadio" id="z8" style="margin-left:-.5em;">
                                <label class="form-check-label" for="z8">8</label>
                            </div>
                            </div>
                        </div>

                        <div class="form-group  text-left">
                          <label class="form-label" for="radio">Repas</label>
                          <div class="single-plan-check shadow-sm active-effect">
                            <div class="form-check mb-0">
                                <input v-model="radioRepas" value="Panier" class="form-check-input" type="radio" name="repas" id="Panier">
                                <label class="form-check-label" for="Panier">Panier</label>
                            </div>
                          </div>
                          <div class="single-plan-check shadow-sm active-effect">
                            <div class="form-check mb-0">
                                <input v-model="radioRepas" value="Restaurant" class="form-check-input" type="radio" name="repas" id="Restaurant">
                                <label class="form-check-label" for="Restaurant">Restaurant</label>
                            </div>
                          </div>
                        </div>
                      </div>

                    <div class="tab-pane fade" id="npm" role="tabpanel" aria-labelledby="npm-tab">
                        <div class="form-check mb-0 text-left">
                          <input v-model="radioActivite" value="Atelier" class="form-check-input" type="radio" name="planChoose" id="Atelier">
                          <label class="form-check-label" for="Atelier">Atelier</label>
                        </div>
                        <div class="form-check mb-0 text-left">
                          <input v-model="radioActivite" value="Congé" class="form-check-input" type="radio" name="planChoose" id="Congé">
                          <label class="form-check-label" for="Congé">Congé</label>
                        </div>
                        <div class="form-check mb-0 text-left">
                          <input v-model="radioActivite" value="Absence" class="form-check-input" type="radio" name="planChoose" id="Absence">
                          <label class="form-check-label" for="Absence">Absence</label>
                        </div>
                        <div class="form-check mb-0 text-left">
                          <input v-model="radioActivite" value="Formation" class="form-check-input" type="radio" name="planChoose" id="Formation">
                          <label class="form-check-label" for="Formation">Formation</label>
                        </div>
                        <div class="form-check mb-0 text-left">
                          <input checked="checked" v-model="radioActivite" value="Intervention" class="form-check-input" type="radio" name="planChoose" id="Intervention">
                          <label class="form-check-label" for="Intervention">Intervention</label>
                        </div>
                        <div class="form-check mb-0 text-left">
                          <input v-model="radioActivite" value="Chantier particulier" class="form-check-input" type="radio" name="planChoose" id="Chantier">
                          <label class="form-check-label" for="Chantier">Chantier particulier</label>
                        </div>
                        <div class="form-check mb-0 text-left">
                          <input v-model="radioActivite" value="Autre" class="form-check-input" type="radio" name="planChoose" id="Autre">
                          <label class="form-check-label" for="Autre">Autre</label>
                        </div>
                    </div>
                </div>
                </div>
            
                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="boutonJournee"
                  input-class="btn btn-success w-100 text-white mt-3"
                />
            </FormulateForm> 
            </div>

            <div v-if="isDone" class="card bg-danger rounded-0 rounded mt-2">
                <div class="card-body text-center py-3">
                  <h6 class="mb-0 text-white line-height-1">Clôture déjà effectuée</h6>
                </div>
            </div>
        </div>
        
        </div> 
    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import router from '../router'
import UserService from '../services/user.service';

var temp = Object.freeze({
     date: '',
     zone: '',
     repas: '',
     collaborateur: '',
     activite: '',
    });

export default {
  name: 'Journee',
  data: function() {
    return {
      lsUserData: [],
      loggedIn: this.$store.state.auth.status.loggedIn,
      formType: '',
      journee: Object.assign({}, temp),
      journeeNewId: null,
      radioRepas: 'Panier',
      radioZone: 'Zone 1',
      radioActivite: 'Intervention',
      today: null,
      isDone: false,
    }
  },
  components: {
    Header,
    Footer,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {
    this.lsUserData = JSON.parse(localStorage.getItem('userData'));

    await UserService.getJournees('?cId=' + this.lsUserData.collaborateur.id).then(
        response => {
          var journees = response.data['hydra:member'];
           journees.forEach(element => {
            if (new Date(element.date).toLocaleDateString() === this.today) {
              this.isDone = true;
            }
          });
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
     
  },
  mounted() {
      this.journee.date = new Date();
      this.today = new Date().toLocaleDateString();
  },
  methods: {
    async reload() {
      await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
          this.userData = JSON.parse(localStorage.getItem('userData'));
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
            const journee = {
              date: this.journee.date,
              zone: this.radioZone,
              collaborateur: "api/collaborateurs/" + this.lsUserData.collaborateur.id,
              repas: this.radioRepas,
              activite: this.radioActivite,
              createdBy: "/api/users/" + this.lsUserData.user.id,
            };
            await UserService.postJournee(journee).then(
                res => {
                  let journeeId = res.data['@id'];
                  this.journeeNewId = journeeId.slice(13);
                    UserService.getUserMe().then(
                        response => {
                            localStorage.setItem('userData', response.data);
                        },
                        error => {
                            this.content = (error.response && error.response.data) || error.message || error.toString();
                        }
                    );
                },
                
            );
            router.push('/journee/' + this.journeeNewId);
            this.reload();
    },  
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>