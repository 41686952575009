<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left ml-2 mr-2"></i><span class="ml-2">&nbsp; &nbsp;</span>Accueil</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">
        <div class="card  rounded-0 rounded-top bg-primary bg-img" style="background-image: url('img/core-img/1.png')">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Documents</h6>
          </div>
        </div>

        
        <ul v-if="documents.length > 0 " class="ps-0 chat-user-list rounded-0">
          <li v-for="(document, index) in documents" :key="document.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (documents.length-1) }">
            <a class="d-flex" v-bind:href="urlUploads + document.filename">
              
              <div class="chat-user-info">
                <h6 class="mt-2 mb-1">{{document.nom|capitalize}}</h6>
              </div>
            </a>
            <div class="dropstart chat-options-btn">
              <a v-bind:href="urlUploads + document.filename" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </li>
        </ul>
        <div v-else class="card bg-danger rounded-0 rounded mt-2">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Aucun document</h6>
          </div>
        </div>
        
    </div>

    <div class="pb-3"></div>

    <Footer />

  </div></div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'Documents',
  data: function() {
    return {
      lsUserData: [],
      documents: [],

      loggedIn: this.$store.state.auth.status.loggedIn,
      urlUploads: this.$urlUploads,
    }
  },
  components: {
    Header,
    Footer,
  },
  filters: {
    capitalize: function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  }, 
  async created() {
    this.lsUserData = JSON.parse(localStorage.getItem('userData'));

    if(this.lsUserData.collaborateur.length === 0) {
      this.documents = [];
    } else {
    await UserService.getDocuments('?cId=' + this.lsUserData.collaborateur.id).then(
          response => {
            this.documents = response.data['hydra:member'];
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        )
    }
  },
   
}
</script>