import axios from "axios";

const instance = axios.create({
  baseURL: "https://okbt.fr/api/",
  //baseURL: "https://127.0.0.1:8000/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
